import React from "react";
import { Link } from "react-router-dom"; // Import Link dari react-router-dom
import ".././style/beranda.css";
import KontakInformasi from "./KontakInformasi";
import PengajuanComponent from "./PengajuanComponent";
import HeaderComponent from "./HeaderComponent";
import menu1Image from ".././img/legal.jpg"; // Import gambar untuk menu 1
import menu2Image from ".././img/approve.jpg"; // Import gambar untuk menu 2
import menu3Image from ".././img/document.jpg"; // Import gambar untuk menu 3
import menu4Image from ".././img/login.png"; // Import gambar untuk menu 3


const Beranda = (props) => {
  const menuStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  };

  const itemStyle = {
    margin: '10px 0',
    fontSize: '18px',
    color: '#333',
    marginTop: '30px',
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '30px',
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const itemHoverStyle = {
    transform: 'scale(1.05)',
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="app-container">
      <HeaderComponent />
      <main className="body">
        <div style={menuStyle}>
          {/* Menu 1: PERSYARATAN PENGAJUAN UANG DUKA */}
          <Link to="/alur-pengajuan" style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
            <div 
              style={{
                ...itemStyle,
                backgroundImage: `url(${menu1Image})`,
                color: 'white',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = itemHoverStyle.transform;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            >
              PERSYARATAN PENGAJUAN UANG DUKA
            </div>
          </Link>

          {/* Menu 2: PENGAJUAN UANG DUKA */}
          <Link to="/pengajuan" style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
            <div 
              style={{
                ...itemStyle,
                backgroundImage: `url(${menu2Image})`,
                color: 'white',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = itemHoverStyle.transform;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            >
              PENGAJUAN UANG DUKA
            </div>
          </Link>

          {/* Menu 3: CEK SANTUNAN UANG DUKA */}
          <Link to="/hasil/0" style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
            <div 
              style={{
                ...itemStyle,
                backgroundImage: `url(${menu3Image})`,
                color: 'white',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = itemHoverStyle.transform;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            >
              CEK SANTUNAN UANG DUKA
            </div>
          </Link>

          <Link to="/login" style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
            <div 
              style={{
                ...itemStyle,
                backgroundImage: `url(${menu4Image})`,
                color: 'white',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = itemHoverStyle.transform;
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
              }}
            >
              LOGIN
            </div>
          </Link>
        </div>
      </main>
      <KontakInformasi />
      <footer className="footer">
        Sistem Penyaluran Dana Santunan Kematian &copy; {currentYear}
      </footer>
    </div>
  );
};

export default Beranda;